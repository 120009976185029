<template>
    <Modal-aviso title="CARO USUÁRIO!" primarybuttontext="Ok" :primarybuttoncallback="closeModal" v-if="showModal"> 
        <p>
            Notamos que a data de compra do seu produto é maior do que o período permitido de contemplação de garantia adicional.
        </p>
        <p>
            Mas você pode continuar aproveitando o Clube Powertech, acompanhar os vídeos exclusivos e cadastrar novos produtos.
        </p>
   </Modal-aviso>
</template>
<script>
   export default {
       name: 'modal-aviso-garantia-expirada',
       props: {
           "openModal": Boolean,
           "reloadModal": {
               type: Boolean,
               default: true
           }
       },
       data: function () {
           return {
               showModal: false,
           };
       },
       watch: {
            openModal: function (val) {
                this.showModal = val
            },
       },
       methods: {
           closeModal: function () {
               this.showModal = false

               if (this.reloadModal) {
                   window.location.reload();
               }
           },
       },
       mounted() {
        this.showModal = this.openModal
       }
   }
</script>